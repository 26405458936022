import { QueryResult } from '@apollo/client';
import useAuth from 'context/Auth';
import { USER_DETAILS_QUERY } from 'graphql/account/queries/userDetailsQuery';
import { OptionsType } from 'hooks/api/graphql/functions/useLandaLazyQuery';
import { useLandaApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useCallback, useEffect, useMemo } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { CreateOrUpdateAppInfo } from 'stores/UserDetails/actions';
import useUserDetailsStore from 'stores/UserDetails/userDetails';
import { UserDetailsTypes } from 'types/user-details-types';

const init: {
  userDetails?: UserDetailsTypes;
  loading: boolean;
  request?: ((innerConfig?: OptionsType) => Promise<QueryResult>) | undefined;
  isTenant?: boolean;
} = {
  userDetails: undefined,
  loading: true,
  request: undefined,
  isTenant: false,
};

const useUserDetails = () => {
  const { isLoggedIn } = useAuth();
  const { userDetails, setUserDetails } = useUserDetailsStore();
  const [innerRequest, { loading }] = useLandaApiLazyQuery(USER_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
  });
  const isTenant = useMemo(() => !!userDetails?.isTenant, [userDetails]);

  const request = async (innerConfig: OptionsType = {}) => {
    const response = await innerRequest(innerConfig);

    setUserDetails({
      ...response?.data?.account?.userDetails,
      rentUser: { ...response?.data?.account?.rentUser },
    });
    await CreateOrUpdateAppInfo(response?.data?.account?.userDetails);
    return response;
  };
  useEffect(() => {
    if (!isLoggedIn) return;
    request();
  }, [isLoggedIn]);

  return {
    request,
    loading: loading && !userDetails,
    userDetails,
    isTenant,
  };
};

export default singletonHook(init, useUserDetails);
