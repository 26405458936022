import { USER_STATUS } from 'consts/User/status';
import useBankName from 'hooks/requests/financials/useBankName';
import useHasBankConnection from 'hooks/requests/financials/useHasBankConnection';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import { getBankStateProps } from 'pages/profile/components/ProfileTabContent/Bank&Transfers/components/BankStateSection/utils';
import { useEffect, useState } from 'react';
import useGlobalLoader from 'stores/GlobalLoader/globalLoader';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { useLandaApiMutation } from 'hooks/api/graphql/landa_api';
import { ACCOUNT_DETAILS } from 'graphql/mutations/accountDetailsMutation';
import { QUILTT_SESSION } from 'graphql/mutations/quilttSessionMutation';

const useBankStateSectionData = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeBankDialog = () => {
    setModalOpen(false);
  };

  const openBankDialog = () => {
    setModalOpen(true);
  };

  const { hasBankConnection, loading: hasBankConnectionLoading } =
    useHasBankConnection();
  const { bankLoading } = useGlobalLoader();
  const [accountDetails] = useLandaApiMutation(ACCOUNT_DETAILS);
  const [
    quilttSession,
    { data: quilttSessionData, loading: quilttSessionLoading, error },
  ] = useLandaApiMutation(QUILTT_SESSION);
  const [isMicroDepositOpen, setIsMicroDepositOpen] = useState(false);

  const {
    bankName,
    accountLastDigits,
    loadingBankConnection: bankNameLoading,
  } = useBankName();

  const { userDetails, loading: userLoading } = useUserDetails();
  const { navigate } = useLandaRouter();
  const openMicroDepositDialog = () => setIsMicroDepositOpen(true);
  const closeMicroDepositDialog = () => setIsMicroDepositOpen(false);
  const [disconnectOpen, setDisconnectOpen] = useState(false);

  const handleQuilttSession = async () => {
    try {
      const token = JSON.stringify(localStorage.getItem('token'));

      await quilttSession({
        variables: {
          token: token,
          userId: userDetails?.userId,
        },
      });
    } catch (e) {
      console.log(e, 'eererer');
    }
  };

  useEffect(() => {
    handleQuilttSession();
  }, []);

  const contactUsLink = () => {
    navigate('https://www.landa.app/contact-us', {
      href: 'https://www.landa.app/contact-us',
    });
  };

  const {
    bankTitle,
    accountDigits,
    buttonText,
    disabled,
    onClick,
    disconnectClick,
    userState,
  } = getBankStateProps(
    bankName,
    hasBankConnection,
    userDetails?.userStage as USER_STATUS,
    accountLastDigits,
    openBankDialog,
    openMicroDepositDialog,
    () => setDisconnectOpen(true),
    contactUsLink
  );

  const loading = hasBankConnectionLoading || bankNameLoading || bankLoading;

  return {
    bankTitle,
    accountDigits,
    buttonText,
    disabled,
    quilttDisabled: !!error,
    onClick,
    modalOpen,
    openBankDialog,
    closeBankDialog,
    disconnectClick,
    loading,
    disconnectOpen,
    setDisconnectOpen,
    hasBankConnection,
    accountDetails,
    userState,
    isMicroDepositOpen,
    closeMicroDepositDialog,
    sessionToken:
      quilttSessionData?.createAccountDetailsToken?.quilttToken?.token ?? '',
    sessionLoading: quilttSessionLoading,
  };
};

export default useBankStateSectionData;
