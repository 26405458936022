import GlobeIcon from 'public/assets/icons/globe.svg';
import HomeIcon from 'public/assets/icons/home_basic.svg';
import PortfolioIcon from 'public/assets/icons/portfolio.svg';
import UserBaseIcon from 'public/assets/icons/user_base.svg';
import WalletIcon from 'public/assets/icons/wallet.svg';
import ConstructionIcon from 'public/assets/icons/constraction.svg';
import { LANDA_APP } from '../../../consts/routes/urls';

export const NAV_BAR_ROUTES = [
  // {
  //   name: 'Home',
  //   route: '/home',
  //   Icon: HomeIcon,
  // },
  {
    name: 'Properties',
    route: '/feed',
    Icon: HomeIcon,
  },
  {
    name: 'Lend',
    route: '/lend',
    Icon: GlobeIcon,
  },
  {
    name: 'Portfolio',
    route: '/portfolio',
    Icon: PortfolioIcon,
  },
  {
    name: 'Activity',
    route: '/activity',
    Icon: WalletIcon,
  },
  {
    name: 'Profile',
    route: '/profile',
    Icon: UserBaseIcon,
  },
];

export const NAV_BAR_ROUTES_MOBILE = [
  // {
  //   name: 'Home',
  //   route: '/home',
  //   Icon: HomeIcon,
  // },
  {
    name: 'Properties',
    route: '/feed',
    Icon: HomeIcon,
  },
  {
    name: 'Portfolio',
    route: '/portfolio',
    Icon: PortfolioIcon,
  },
  {
    name: 'Lend',
    route: '/lend',
    Icon: GlobeIcon,
  },
  {
    name: 'Activity',
    route: '/activity',
    Icon: WalletIcon,
  },
  {
    name: 'Profile',
    route: '/profile',
    Icon: UserBaseIcon,
  },
];

export const NAV_BAR_ROUTES_TENANT = [
  {
    name: 'Properties',
    route: '/feed?occupancyStatuses=marketing',
    Icon: HomeIcon,
  },
  {
    name: 'Payments',
    route: '/activity',
    Icon: WalletIcon,
  },
  {
    name: 'Requests',
    route: undefined,
    Icon: ConstructionIcon,
    link: `${LANDA_APP}/residents/maintenance-ticket`,
  },
  {
    name: 'Profile',
    route: '/profile',
    Icon: UserBaseIcon,
  },
];
